<template>
  <Layer width="40rem" @close="$emit('close')" title="Publishing Preview">
    <p v-if="loadingProducts" class="m-5 text-center">Loading Publishing Preview...</p>
    <div v-else-if="changelog" class="p-4">
      <div class="mb-4">
        <h2 class="h2 mb-3">Added Products</h2>
        <ul class="list-group">
          <li class="list-group-item" v-for="product in changelog.added" :key="product.id">
            {{ product.brandName }} {{ product.label }}
          </li>
          <li v-if="changelog.added.length === 0" class="list-group-item list-group-item-secondary">
            No products will be changed
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h2 class="h2 mb-3">Changed Products</h2>
        <ul class="list-group">
          <li class="list-group-item" v-for="item in changelog.changed" :key="item.product.id">
            <strong class="d-block ml-3 mb-2"
              >{{ item.product.brandName }} {{ item.product.label }} ({{ item.product.id }})</strong
            >
            <ul class="list-group">
              <li class="list-group-item" v-for="(change, i) in item._changes" :key="i">
                <strong class="d-block mb-2">{{ toCapitalizedWords(change.field) }}</strong>
                <div v-if="change.field === 'eans'" class="d-flex align-items-center justify-content-between">
                  <ul class="list-group">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(ean, i) in change.oldValue"
                      :key="i"
                    >
                      <span>{{ ean.ean }}</span>
                      <span class="badge badge-secondary ml-2">{{ ean.amount }}</span>
                    </li>
                  </ul>
                  <p class="badge badge-info mb-0">➔</p>
                  <ul class="list-group">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(ean, i) in change.newValue"
                      :key="i"
                    >
                      <span>{{ ean.ean }}</span>
                      <span class="badge badge-secondary ml-2">{{ ean.amount }}</span>
                    </li>
                  </ul>
                </div>
                <span v-else>
                  {{ change.oldValue }}
                  <p class="badge badge-info ml-1 mr-1 mb-0">➔</p>
                  {{ change.newValue }}
                </span>
              </li>
            </ul>
          </li>
          <li v-if="changelog.changed.length === 0" class="list-group-item list-group-item-secondary">
            No products will be changed
          </li>
        </ul>
      </div>
      <div class="mb-4">
        <h2 class="h2 mb-3">Unpublished Products</h2>
        <ul class="list-group">
          <li class="list-group-item" v-for="product in changelog.unpublished" :key="product.id">
            {{ product.id }} {{ product.brandName }} {{ product.label }}
          </li>
          <li v-if="changelog.unpublished.length === 0" class="list-group-item list-group-item-secondary">
            No products will be unpublished
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <button v-if="!publishInProgress" class="btn btn-primary" @click="publishProducts">Publish</button>
        <button v-else class="btn btn-primary" disabled>Publishing...</button>
      </div>
    </div>
  </Layer>
</template>

<script>
import Layer from '@/shared/Layer'
import { db } from '@/shared/firebase'
import multiBatch from '@/shared/multiBatch'
import catalogJSON from '@/data/catalog.json'

const fields = [
  'additionalLabel',
  'brandName',
  'categoryId',
  'containerDeposit',
  'description',
  'eans',
  'gender',
  'img',
  'label',
  'order',
  'packageSize',
  'packageUnit',
  'packsPerCarton',
  'retailPrice',
  'temporarilyUnavailable'
]

export default {
  components: { Layer },
  data() {
    return {
      loadingProducts: false,
      changelog: null,
      publishInProgress: false
    }
  },
  async created() {
    this.loadingProducts = true
    this.changelog = null

    const newProducts = this.$store.state.products
    const oldProducts = await db
      .collection('public/catalog/products')
      .get()
      .then(snapshot => {
        return snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
      })
    const changelog = { added: [], changed: [], unpublished: [] }
    newProducts
      .filter(p => p.live)
      .forEach(newProduct => {
        if (!oldProducts.find(p => p.id === newProduct.id)) changelog.added.push(newProduct)
      })
    oldProducts.forEach(oldProduct => {
      if (!newProducts.filter(p => p.live).find(p => p.id === oldProduct.id)) changelog.unpublished.push(oldProduct)
    })
    newProducts.forEach(newProduct => {
      const oldProduct = oldProducts.find(p => p.id === newProduct.id)
      if (oldProduct) {
        let isUnchanged = true
        const changes = []
        fields.forEach(field => {
          if (!_.isEqual(oldProduct[field], newProduct[field])) {
            isUnchanged = false
            changes.push({
              field,
              oldValue: oldProduct[field],
              newValue: newProduct[field]
            })
          }
        })
        if (!isUnchanged) changelog.changed.push({ product: newProduct, _changes: changes })
      }
    })
    this.loadingProducts = false
    console.log(changelog)
    this.changelog = changelog
  },
  methods: {
    publishProducts: async function() {
      this.publishInProgress = true
      const batch = new multiBatch()
      batch.set(db.doc('public/catalog'), catalogJSON)
      this.$store.state.products.forEach(product => {
        const publicProductRef = db.doc(`public/catalog/products/${product.id}`)
        const privateProductRef = db.doc(`private/catalog/products/${product.id}`)
        const cleanProduct = {}
        fields.forEach(field => {
          if (typeof product[field] !== 'undefined') cleanProduct[field] = product[field]
        })
        if (product.live) {
          batch.set(publicProductRef, cleanProduct)
        } else {
          batch.delete(publicProductRef)
        }
        batch.update(privateProductRef, {
          liveRetailPrice: product.retailPrice
        })
      })
      batch.commit().then(() => {
        this.publishInProgress = false
        this.$emit('close')
      })
    },
    toCapitalizedWords(name) {
      var words = name.match(/[A-Za-z][a-z]*/g) || []

      return words
        .map(word => {
          return word.charAt(0).toUpperCase() + word.substring(1)
        })
        .join(' ')
    }
  }
}
</script>
