<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['products', 'shelves', 'purchase'])" />
  <div v-else>
    <div
      class="d-print-none mb-4 d-flex justify-content-between align-items-center fixed-top d-print-none"
      style="margin: 4rem 2rem; background: #fff; padding: 1.5rem 0 1.5rem; z-index: 10; border-bottom: 1px #ccc solid;"
    >
      <h1 class="h2">
        Products
      </h1>
      <div class="d-flex align-items-center">
        <DotMenu keepOpen :highlightRows="false" handleText="Options" class="mr-3">
          <li class="mr-2 text-left">
            <div class="custom-control custom-switch d-inline">
              <input type="checkbox" class="custom-control-input" id="showUnlisted" v-model="filters.showUnlisted" />
              <label class="custom-control-label" for="showUnlisted">Include Unlisted</label>
            </div>
          </li>
          <li class="mr-2 text-left">
            <div class="custom-control custom-switch d-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="onlyTempUnavailable"
                v-model="filters.onlyTempUnavailable"
              />
              <label class="custom-control-label" for="onlyTempUnavailable">Only Temporarily Unavailable</label>
            </div>
          </li>
          <li class="mr-2 text-left">
            <div class="custom-control custom-switch d-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="onlyOutOfStock"
                v-model="filters.onlyOutOfStock"
              />
              <label class="custom-control-label" for="onlyOutOfStock">Only Out of Stock</label>
            </div>
          </li>
          <li class="mr-2 text-left">
            <div class="custom-control custom-switch d-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="onlyInvalidProductLinks"
                v-model="filters.onlyInvalidProductLinks"
              />
              <label class="custom-control-label" for="onlyInvalidProductLinks">Only with Invalid Product Links</label>
            </div>
          </li>

          <li class="text-left">
            <label class="d-block font-weight-bold mt-2">Source:</label>
            <select class="custom-select" id="source" style="width: 220px;" v-model="filters.source">
              <option :value="null">All</option>
              <option v-for="source in sources" :key="source.id" :value="source.id">
                {{ source.label }}
              </option>
            </select>
          </li>

          <li class="text-left">
            <label class="d-block font-weight-bold mt-2">Offers:</label>
            <select class="custom-select" id="offers" style="width: 220px;" v-model="filters.offers">
              <option :value="null">No filtering</option>
              <option :value="true">Only with Offers</option>
              <option :value="false">Only without Offers</option>
              <optgroup label="Source">
                <option v-for="source in sources" :key="source.id" :value="source.id">
                  {{ source.label }} ({{ source.id }})
                </option>
              </optgroup>
            </select>
          </li>

          <li class="text-left">
            <label class="d-block font-weight-bold mt-2">Rebuy Amount:</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Min" v-model="filters.minRebuyAmount" />
              <input type="text" class="form-control" placeholder="Max" v-model="filters.maxRebuyAmount" />
            </div>
          </li>

          <li class="mt-3 text-left">
            <span class="d-block font-weight-bold mb-2">Source:</span>
            <div class="custom-control custom-switch d-inline">
              <input type="checkbox" class="custom-control-input" id="showEans" v-model="showEans" />
              <label class="custom-control-label" for="showEans">Show EANs</label>
            </div>
          </li>
          <li class=" text-left">
            <div class="custom-control custom-switch d-inline">
              <input type="checkbox" class="custom-control-input" id="printSuggestions" v-model="printSuggestions" />
              <label class="custom-control-label" for="printSuggestions">Print Suggestions</label>
            </div>
          </li>
        </DotMenu>
        <router-link
          :to="{ name: 'product', params: { productId: createProductId(), newProduct: true } }"
          class="btn btn-success btn-sm mr-3"
        >
          Add Product
        </router-link>
        <button class="btn btn-outline-primary btn-sm mr-3" @click="showPublishingLayer = true">Publish</button>
        <DotMenu>
          <li @click="exportProductSheet">Download Excel-Export</li>
          <li @click="exportKioskProducts">Download Kioskproducts</li>
          <li @click="export4WeeksProductSales">Download 4 Weeks Sales</li>
          <li @click="showSourcesEditor = true">Show Sources Editor</li>
        </DotMenu>
      </div>
    </div>
    <table class="table align-center" style="margin-top: 4rem;">
      <tbody v-for="category in productsByCategories" :key="category.id">
        <tr v-if="category.products.length > 0" class="table-info">
          <td colspan="3">{{ category.label }} ({{ category.id }} / {{ category.products.length }})</td>
          <td class="d-print-none">Reference</td>
          <td class="d-print-none">Retail</td>
          <td class="text-right d-print-none">Stock</td>
          <td><span class="d-print-none">Rebuy</span><span class="d-none d-print-block">Menge</span></td>
          <td :class="{ 'd-print-none': !printSuggestions }">Suggestion</td>
          <td class="text-right d-print-none">ø Weekly Sales</td>
          <td class="d-print-none"></td>
          <td v-if="showEans"></td>
        </tr>
        <tr
          v-for="product in category.products"
          :key="product.id"
          :class="{
            'products__product-alert': !product.live,
            'products__product-warning': product.temporarilyUnavailable
          }"
        >
          <td width="50px" class="align-middle text-right" @click="setOrderKey(product.id)">
            <span class="d-print-none">{{ product.order }}.</span>
          </td>
          <td width="50px">
            <div
              :style="
                `background-image: url(https://storage.googleapis.com/${
                  env === 'production' ? 'emma-static' : 'emma-static-dev'
                }/products/${product.img}_256.jpg)`
              "
              class="products__product-img"
            />
          </td>
          <td>
            {{ product.brandName }} {{ product.label }} {{ product.additionalLabel }}
            <span class="d-none d-print-inline">({{ product.packageSize }} {{ product.packageUnit }})</span>
            <small style="display: block" class="d-print-none">
              {{ product.id }} / {{ product.packageSize }} {{ product.packageUnit }}
              <span v-if="product.source === 'EDEKA Foodservice' && !product.offers" title="No Offer">⚠️</span>
              / {{ product.sourceLabel }}
              <span v-if="product.packsPerCarton">/ Carton: {{ product.packsPerCarton }}</span>
            </small>
          </td>
          <td class="align-middle d-print-none">
            <span
              :class="{
                'text-danger': product.priceLookup && product.priceLookup.regularPrice !== product.referencePrice
              }"
              >{{ formatCurrency(product.referencePrice) }}</span
            >
            <div>
              <small v-if="product.productLink && product.productLink.valid === false" title="Product Link is invalid"
                >⚠️ INV</small
              >
              <small v-if="product.priceLookup">
                <span :style="product.priceLookup.reducedPrice ? 'text-decoration: line-through;' : ''">{{
                  formatCurrency(product.priceLookup.regularPrice)
                }}</span>
                <span class="ml-2" v-if="product.priceLookup.reducedPrice">{{
                  formatCurrency(product.priceLookup.reducedPrice)
                }}</span>
              </small>
            </div>
          </td>
          <td class="align-middle d-print-none">
            <span v-if="product.liveRetailPrice !== product.retailPrice"
              >{{ formatCurrency(product.liveRetailPrice) }} ➡️
            </span>
            <span>{{ formatCurrency(product.retailPrice) }}</span>
          </td>
          <td
            class="text-right align-middle d-print-none"
            :class="{ 'text-danger': product.inStock < product.minStock }"
          >
            {{ product.inStock }} /
            <button
              class="btn btn-sm"
              :class="product.inStock < product.minStock ? 'btn-outline-danger' : 'btn-outline-secondary'"
              @click="setMinStock(product.id)"
            >
              {{ product.minStock }}
            </button>
          </td>
          <td class="align-middle">
            <button class="btn btn-outline-secondary btn-sm" @click="setRebuyAmount(product.id)">
              {{ product.rebuyAmount }}
            </button>
          </td>
          <td class="align-middle" :class="{ 'd-print-none': !printSuggestions }">
            <small class="">
              {{ product.rebuySuggestion }}
              <span class="badge badge-pill" :style="`background: hsl(${product.rebuyColorCode},100%,50%)`">
                {{ product.rebuySuggestionDiviation }} %
              </span>
            </small>
          </td>
          <td class="text-right align-middle d-print-none">
            <DotMenu v-if="product.averageSales" :handleText="product.averageSales.last4weeks" :highlightRows="false">
              <li>ø 4 Weeks: {{ product.averageSales.last4weeks }}</li>
              <li>ø 12 Weeks: {{ product.averageSales.last12weeks }}</li>
              <li>Total: {{ product.averageSales.total }}</li>
            </DotMenu>
          </td>
          <td class="text-center align-middle d-print-none">
            <router-link
              :to="{ name: 'product', params: { productId: product.id } }"
              class="btn btn-outline-primary btn-sm"
              event=""
              @click.native.prevent="productLayerId = product.id"
            >
              Details
            </router-link>
            <button
              class="btn btn-sm ml-2"
              type="button"
              :class="{ 'btn-light': !product.hideFromCatalog, 'btn-warning': product.hideFromCatalog }"
              @click="toggleHideFromCatalog(product.id)"
            >
              HFC
            </button>
          </td>
          <td v-if="showEans">
            <svg width="200px" class="barcode" :jsbarcode-value="product.latestEAN.ean" v-if="product.latestEAN"></svg>
          </td>
        </tr>
      </tbody>
    </table>
    <Layer width="75rem" v-if="productLayerId" @close="productLayerId = null" title="Edit Product">
      <ProductForm :productId="productLayerId" @saved="productLayerId = null" class="ml-3 mr-3" />
      <hr />
      <h2 class="ml-5 mb-4">Sales Statistics</h2>
      <ProductSalesChart :weeksToShow="52" :productId="productLayerId" class="ml-2 mr-2 mb-3" />
      <KioskProductsListing :productId="productLayerId" />
    </Layer>
    <Layer v-if="showSourcesEditor" title="Sources of Supply" @close="showSourcesEditor = false" padding="1rem">
      <ul class="list-group">
        <li v-for="source in sources" :key="source.id" class="list-group-item">
          <strong class="d-block mb-1">{{ source.label }}</strong>
          <div
            v-for="feature in ['orderingCycle', 'deliveryTime', 'buffer']"
            :key="feature"
            class="d-inline-block mr-3"
          >
            <small class="d-block mb-1">{{ feature | toCapitalizedWords }}:</small>
            <button class="btn btn-sm btn-outline-secondary d-block" @click="setSourceFeature(source.id, feature)">
              {{ typeof source[feature] !== 'undefined' ? source[feature] : '?' }} days
            </button>
          </div>
        </li>
      </ul>
    </Layer>
    <PublishProductsLayer v-if="showPublishingLayer" @close="showPublishingLayer = false" />
  </div>
</template>

<script>
import KioskProductsListing from '@/components/KioskProductsListing'
import ProductForm from '@/components/ProductForm'
import ProductSalesChart from '@/components/ProductSalesChart'
import PublishProductsLayer from '@/components/PublishProductsLayer'
import catalogJSON from '@/data/catalog.json'
import productImportFieldsJSON from '@/data/productimportfields.json'
import { CalculateCatalog } from '@/helpers/CalculateCatalog'
import DotMenu from '@/shared/DotMenu'
import Layer from '@/shared/Layer'
import { db, env, fb } from '@/shared/firebase'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import JsBarcode from 'jsbarcode'

export default {
  components: { DotMenu, Layer, ProductForm, ProductSalesChart, PublishProductsLayer, KioskProductsListing },
  data() {
    return {
      productImportFields: productImportFieldsJSON,
      market: false,
      env,
      filters: {
        source: null,
        showUnlisted: false,
        onlyTempUnavailable: false,
        onlyOutOfStock: false,
        onlyInvalidProductLinks: false,
        offers: null,
        minRebuyAmount: '',
        maxRebuyAmount: ''
      },
      showEans: false,
      showSourcesEditor: false,
      printSuggestions: false,
      productLayerId: null,
      showPublishingLayer: false
    }
  },
  computed: {
    sources() {
      const sources = this.$store.state.purchase.sources
      return _.orderBy(
        Object.keys(sources).map(id => {
          return { ...sources[id], id }
        }),
        [source => source.label.toLowerCase()]
      )
    },
    productsByCategories() {
      let products = this.$store.state.products
      const {
        showUnlisted,
        onlyTempUnavailable,
        source,
        offers,
        minRebuyAmount,
        maxRebuyAmount,
        onlyOutOfStock,
        onlyInvalidProductLinks
      } = this.filters
      if (!showUnlisted) {
        products = products.filter(p => p.live)
      }
      if (onlyTempUnavailable) {
        products = products.filter(p => p.temporarilyUnavailable)
      }
      if (source) {
        products = products.filter(p => p.source === source)
      }
      if (offers !== null) {
        if (typeof offers === 'string') {
          products = products.filter(p => typeof p.offers !== 'undefined' && p.offers.find(o => o.source === offers))
        } else {
          products = products.filter(p => (offers ? typeof p.offers !== 'undefined' : typeof p.offers === 'undefined'))
        }
      }
      if (minRebuyAmount) products = products.filter(p => p.rebuyAmount >= minRebuyAmount)
      if (maxRebuyAmount) products = products.filter(p => p.rebuyAmount <= maxRebuyAmount)
      const stockCounts = {}
      this.$store.state.shelves.forEach(shelf => {
        Object.keys(shelf.spaces).forEach(spaceId => {
          shelf.spaces[spaceId].items.forEach(item => {
            stockCounts[item.productId] = (stockCounts[item.productId] || 0) + item.amount
          })
        })
      })
      products.forEach(product => {
        if (product.salesByWeek) {
          const salesArray = Object.keys(product.salesByWeek)
            .sort()
            .map(weekKey => {
              return product.salesByWeek[weekKey].sum
            })
          product.averageSales = {
            last4weeks: _.mean(_.takeRight(salesArray, 4)).toFixed(1),
            last12weeks: _.mean(_.takeRight(salesArray, 12)).toFixed(1),
            total: _.sum(salesArray)
          }
          const { last4weeks, last12weeks } = product.averageSales
          const source = this.sources.find(s => s.id === product.source)
          const weeksToConsider = (source.orderingCycle + source.deliveryTime + source.buffer) / 7
          product.rebuySuggestion = Math.round(
            ((parseFloat(last4weeks) + parseFloat(last12weeks)) / 2) * weeksToConsider
          )
          product.rebuySuggestionDiviation =
            product.rebuySuggestion === 0 ? 100 : Math.round((product.rebuyAmount / product.rebuySuggestion) * 100)
          product.rebuyColorCode = 100 - Math.abs(100 - product.rebuySuggestionDiviation)
          if (product.rebuyColorCode < 0) product.rebuyColorCode = 0
        }
        if (product.eans && product.eans.length > 0) {
          const eans = product.eans.filter(e => e.amount === 1)
          product.latestEAN = eans.slice(-1)[0]
        }
        product.inStock = stockCounts[product.id] || 0
        const source = this.sources.find(s => s.id === product.source)
        product.sourceLabel = source ? source.label : 'unknown'
      })
      if (onlyOutOfStock) {
        products = products.filter(p => p.inStock < p.minStock)
      }
      if (onlyInvalidProductLinks) {
        products = products.filter(p => p.productLink && p.productLink.valid === false)
      }
      const catalog = CalculateCatalog(JSON.parse(JSON.stringify(catalogJSON)), {
        flat: true,
        products
      })
      return catalog.categories
    }
  },
  watch: {
    showEans(active) {
      if (active) {
        this.$nextTick(() => {
          JsBarcode('.barcode')
            .options({ displayValue: false, width: 2, height: 40 })
            .init()
        })
      }
    }
  },
  methods: {
    setMinStock(productId) {
      const minStock = parseFloat(prompt('Enter Rebuy Amount'))
      if (!isNaN(minStock)) db.doc(`private/catalog/products/${productId}`).update({ minStock })
    },
    setRebuyAmount(productId) {
      const rebuyAmount = parseFloat(prompt('Enter Rebuy Amount'))
      if (!isNaN(rebuyAmount)) db.doc(`private/catalog/products/${productId}`).update({ rebuyAmount })
    },
    exportProductSheet() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Produkte')

      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'ID', key: 'id', width: 10 },
        { header: 'Label', key: 'label', width: 50 },
        { header: 'Size', key: 'size', width: 10 },
        { header: 'In Stock', key: 'inStock', width: 10 },
        { header: 'Min Stock', key: 'minStock', width: 10 },
        { header: 'Rebuy Amount', key: 'rebuyAmount', width: 10 },
        { header: 'Reference Price', key: 'referencePrice', width: 15 },
        { header: 'Single Item Price', key: 'singleItemPrice', width: 15 },
        { header: 'Buy Amount', key: 'buyAmount', width: 15 },
        { header: 'Buy Price', key: 'buyPrice', width: 15 },
        { header: 'EAN', key: 'ean', width: 20 }
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }

      const categories = this.productsByCategories.filter(c => c.products.length > 0)

      categories.forEach(category => {
        const titleRow = worksheet.addRow({ label: category.label })
        titleRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFAAAAAA'
          },
          bgColor: {
            argb: 'FF000000'
          }
        }
        worksheet.mergeCells(`B${titleRow._number}:D${titleRow._number}`)

        category.products.forEach(product => {
          const eans =
            product.eans && product.eans.filter(e => e.amount === 1).length > 0
              ? product.eans.filter(e => e.amount === 1)
              : null
          let ean = ''
          if (eans) {
            const budniEAN = product.eans.find(e => e.budniEAN)
            if (budniEAN) {
              ean = budniEAN.ean
            } else {
              ean = _.last(eans).ean
            }
          }
          worksheet.addRow({
            id: product.id,
            label: `${product.brandName} ${product.label}`,
            size: `${product.packageSize} ${product.packageUnit}`,
            inStock: product.inStock,
            minStock: product.minStock,
            rebuyAmount: product.rebuyAmount,
            referencePrice: product.referencePrice,
            singleItemPrice: product.EKNet,
            buyAmount: product.inStock < product.minStock ? product.rebuyAmount : 0,
            buyPrice: '',
            ean
          })

          const buyPriceCell = _.nth(_.last(worksheet._rows)._cells, -2)
          const rowNumber = buyPriceCell._row._number
          buyPriceCell.value = { formula: `G${rowNumber}*H${rowNumber}` }
        })
      })

      const footerRow = worksheet.addRow({
        id: '',
        label: '',
        size: '',
        inStock: '',
        minStock: '',
        rebuyAmount: '',
        singleItemPrice: '',
        buyAmount: '',
        buyPrice: '',
        ean: ''
      })
      footerRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      footerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }
      const sumCell = _.nth(footerRow._cells, -2)
      const rowNumber = sumCell._row._number
      sumCell.value = { formula: `SUM(I2:I${rowNumber - 1})` }

      let fileName = this.filters.source ? 'Produktexport ' + this.filters.source : 'Produktexport'

      workbook.xlsx.writeBuffer().then(function(data) {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `${fileName}.xlsx`)
      })
    },
    async export4WeeksProductSales() {
      const orders = await db
        .collection('orders')
        .where('deliveryDate', '>=', new Date('2022-06-22'))
        .where('deliveryDate', '<=', new Date('2022-07-21'))
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      const productSales = {}
      const statTypes = ['resident', 'homeOrder', 'kiosk', 'sum']
      orders.forEach(order => {
        if (!order.statistic) return console.warn('no statistic for order', order.id)
        statTypes.forEach(type => {
          if (order.statistic[type]) {
            Object.keys(order.statistic[type].products).forEach(productId => {
              if (!productSales[productId]) {
                productSales[productId] = {
                  resident: 0,
                  homeOrder: 0,
                  kiosk: 0,
                  sum: 0
                }
              }
              const amount = order.statistic[type].products[productId]
              productSales[productId][type] += amount
            })
          }
        })
      })

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Produktverkäufe')
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'ID', key: 'productId', width: 10 },
        { header: 'Name', key: 'label', width: 50 },
        { header: 'Bewohner', key: 'resident', width: 10 },
        { header: 'Hausbedarf', key: 'homeOrder', width: 10 },
        { header: 'Kiosk', key: 'kiosk', width: 10 },
        { header: 'Summe', key: 'sum', width: 10 }
      ]

      Object.keys(productSales)
        .sort()
        .forEach(productId => {
          const product = this.$store.state.products.find(p => p.id === productId)
          const label = `${product.brandName} ${product.label}, ${product.packageSize} ${product.packageUnit}`
          const { resident, homeOrder, kiosk, sum } = productSales[productId]
          worksheet.addRow({
            productId,
            label,
            resident,
            homeOrder,
            kiosk,
            sum
          })
        })

      workbook.xlsx.writeBuffer().then(data => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `Produktverkäufe.xlsx`)
      })
    },
    exportKioskProducts() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Produkte')

      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'Artikel-Nr.', key: 'id', width: 10 },
        { header: 'Produktname', key: 'name', width: 50 },
        { header: 'Größe', key: 'size', width: 10 },
        { header: 'Einzelpreis', key: 'singleItemPrice', width: 10 },
        { header: 'Menge', key: 'amount', width: 10 },
        { header: 'Summe', key: 'sum', width: 10 }
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }

      const categories = this.productsByCategories.filter(c => c.products.length > 0)

      categories.forEach(category => {
        const titleRow = worksheet.addRow({ name: category.label })
        titleRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'FFAAAAAA'
          },
          bgColor: {
            argb: 'FF000000'
          }
        }
        worksheet.mergeCells(`B${titleRow._number}:D${titleRow._number}`)

        category.products.forEach(product => {
          worksheet.addRow({
            id: product.id,
            name: `${product.brandName} ${product.label}`,
            size: `${product.packageSize} ${product.packageUnit}`,
            singleItemPrice: product.retailPrice,
            amount: 0,
            sum: 0
          })

          const buyPriceCell = _.last(_.last(worksheet._rows)._cells)
          const rowNumber = buyPriceCell._row._number
          buyPriceCell.value = { formula: `D${rowNumber}*E${rowNumber}` }
        })
      })

      const footerRow = worksheet.addRow({
        id: '',
        name: '',
        size: '',
        singleItemPrice: '',
        amount: '',
        sum: ''
      })
      footerRow.font = {
        color: { argb: 'FFFFFF' },
        bold: true
      }
      footerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'FF222222'
        },
        bgColor: {
          argb: 'FFFFFFFF'
        }
      }
      const sumCell = _.nth(footerRow._cells, -1)
      const rowNumber = sumCell._row._number
      sumCell.value = { formula: `SUM(F2:F${rowNumber - 1})` }
      ;['singleItemPrice', 'sum'].forEach(key => {
        worksheet.getColumn(key).numFmt = '#,##0.00 €'
      })

      let fileName = 'Kioskprodukte'
      workbook.xlsx.writeBuffer().then(function(data) {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `${fileName}.xlsx`)
      })
    },
    createProductId: function() {
      let lastId = _.maxBy(this.$store.state.products, 'id')
      if (typeof lastId !== 'undefined') {
        lastId = parseFloat(lastId.id.split('A')[1]) + 1
        lastId = 'A' + lastId
      }
      return lastId
    },
    setOrderKey(productId) {
      const order = parseFloat(prompt('Enter order key'))
      if (!isNaN(order)) {
        db.doc(`/private/catalog/products/${productId}`).update({ order })
      }
    },
    toggleHideFromCatalog(productId) {
      const hideFromCatalog = this.$store.state.products.find(p => p.id === productId).hideFromCatalog
      db.doc(`private/catalog/products/${productId}`).update({
        hideFromCatalog: hideFromCatalog === true ? fb.firestore.FieldValue.delete() : true
      })
    },
    setSourceFeature(sourceKey, feature) {
      const source = this.sources.find(s => s.id === sourceKey)
      const featureLabel = this.$options.filters.toCapitalizedWords(feature)
      let value = prompt(`${featureLabel} for ${source.label} in days?`)
      if (value !== null && value !== '') {
        if (isNaN(value)) return alert(`"${value}" is no valid input`)
        if (value > 365) return alert(`${featureLabel} can't be higher then 365`)
        value = parseFloat(value)
        db.doc(`private/purchase`).update({
          [`sources.${sourceKey}.${feature}`]: value
        })
      }
    }
  },
  filters: {
    toCapitalizedWords(input) {
      var words = input.match(/[A-Za-z][a-z]*/g) || []
      return words
        .map(word => {
          return word.charAt(0).toUpperCase() + word.substring(1)
        })
        .join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  &__product-img {
    width: 48px;
    height: 48px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    float: left;
    margin-right: 1rem;
  }
  &__product-warning {
    background-color: #fff0de;
  }
  &__product-alert {
    background-color: #ffe3e3;
  }
}
</style>
